import React from "react"

import Viewer, { Worker } from '@phuocng/react-pdf-viewer'
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css'

function PDFViewer({pdf}) {
	const filePath = pdf.file.guid
	const viewerStyle =
	{
		width: '100%',
		height: '750px',
		marginTop: '2vh',
		outline: 'none',
	}

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
      <div style={viewerStyle}>
        <Viewer
        	defaultScale={1}
        	fileUrl={filePath} />
      </div>
    </Worker>
   )
}

export default PDFViewer
