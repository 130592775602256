import React, {useState, useEffect} from "react"
import {Link} from "gatsby"

import {CopyToClipboard} from 'react-copy-to-clipboard'
import { useLocation } from "@reach/router"

function CTA() {
  const location = useLocation();
  const [text, setText] = useState(location.href)
  const [copied, setCopied] = useState(false)

  useEffect( () => {
    setTimeout(() => {setCopied(false)}, 2000);
  }, [copied])

  return (
    <div
      style={{
        marginTop: '10vh',
      }}
      className='CTA'>

      {/* Scroll to top button */}
      <div
        style={{
          fontSize: '0.9rem',
          color: '#7EB99C',
          textAlign: 'right'
        }}>
        <Link to={''}> scroll to top &#x2191; </Link>
      </div>

      {/* Horizontal rule */}
      <hr
        style={{
          background: '#7EB99C',
          width: '10vw'
        }}
        />

      <p>Did you find this content interesting? You can <a href="https://lists.coveillance.org/mailman/listinfo/announce_coveillance.org">join our mailing list</a> and support us by:</p>

      {/* Copied to Clipboard text (when it appears) */}
      <div
        style={{
          marginTop: '1vh',
          color: '#7EB99C',
          opacity: copied ? '1' : '0',
          animation: copied ? 'fade 2.5s' : 'none'
        }}
        className='temporaryCopy'>
        Copied to clipboard!
      </div>

        {/* Copied to Clipboard functionality wraps the list */}
        <CopyToClipboard
          text={text}
          onCopy={(e)=>setCopied(true)}>

          <li>
            <button
              style={{
                color: '#7EB99C',
                textDecoration: 'underline',
                fontWeight: '500',
                fontStyle: 'italic',
                outline: 'none',
                background: 'none',
                border: 'none',
                padding: '0',
                margin: '0',
              }}> → sharing this page</button>, so more people can see it.
          </li>
        </CopyToClipboard>

        <li> <a href="https://fundraising.fracturedatlas.org/coveillance-demystifying-surveillance-with-arts-based-methods">→ donating</a> to coveillance, so we can continue to do this work.</li>

        <li> <a href="mailto: coveillance@protonmail.com ">→ giving us feedback</a>, so our work gets even better.</li>

    </div>
   )
}

export default CTA
