import { useStaticQuery, graphql } from "gatsby"

{/*
  React Hook that identifies which Menu items contain the word "Toolkit" and fetches the Pages contained in them.
  These are displayed in List component (landing page).
*/}

export const usePagesFromToolkitMenu = () => {
  const { allWpMenu } = useStaticQuery(
    graphql`
      query {
        allWpMenu(sort: {fields: name, order: ASC}, filter: {name: {regex: "/Toolkit/"}})  {
          nodes {
            name
            menuItems {
              nodes {
                url
                label
                connectedNode {
                  node {
                    ... on WpPage {
                      id
                      title
                      slug
                      date
                      excerpt {
                        text
                      }
                      content
                      featuredImage {
                        node {
                          localFile {
                            childImageSharp {
                              fluid {
                                ...GatsbyImageSharpFluid
                              }
                            }
                          }
                        }
                      }
                    }
                    ... on WpPost {
                      id
                      title
                      slug
                      date
                      content
                      featuredImage {
                        node {
                          localFile {
                            childImageSharp {
                              fluid {
                                ...GatsbyImageSharpFluid
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allWpMenu.nodes
}
