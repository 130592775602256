import React, {useEffect} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import * as tocbot from 'tocbot'
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useLocation } from "@reach/router"

import Header from "../components/header"
import NavBar from "../components/navBar"
import {getMainWrapper, getTocWrapper, getPageWrapper, tocInit, getSideWrapper} from "../utils"

import "../styles/layout.css"
import "../styles/globals_kalli.css"
import "../styles/tocbot.css"
import "../styles/inlineWP.css"

{/*
  All Pages should look the same, so this file sets the layout and sizing for the Module views, as well as includes the TOC bot.
*/}


const Page = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQueryPage {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(()=>{
    tocbot.init(tocInit);
  },[])

  const breakpoints = useBreakpoint()
  const location = useLocation();

  return (
    <div>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div style={getPageWrapper(breakpoints
        )}>
        <div style={getSideWrapper(breakpoints)}>
          <NavBar />
          {!location.pathname.includes('partners') && 
            <nav style={getTocWrapper(breakpoints)} className="js-toc"> </nav>
          }
        </div>
        <main style={getMainWrapper(breakpoints)}>
          {children}
        </main>
      </div>
    </div>
  )
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Page
