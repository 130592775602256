import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import {usePagesFromToolkitMenu} from "../../hooks/usePagesFromToolkitMenu"

function ModuleNavigation({slug}) {
  const menuData = usePagesFromToolkitMenu()
  const pages = menuData[0].menuItems.nodes

  const previousModule = getPrevious(pages, slug);
  const nextModule = getNext(pages, slug)

  return (
    <div style={{
        margin: '10vh auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}>

      {/*
        Previous Module button
      */}

      {previousModule &&
        <Link
          to={`../${previousModule.connectedNode.node.slug}`}
          className='ModuleNavigation'
          style={{
            border: '1px solid #7EB99C',
            borderRadius: '25px',
            padding: '2rem',
            textAlign: 'center',
            minWidth: '20%',
            maxWidth: '40%'
          }}>
          Previous module
        </Link>
      }

      {/*
        Placeholder Previous Module button to be consistent with placing
      */}

      {
        !previousModule && <div></div>
      }

      {/*
        Next Module button
      */}

      {nextModule &&
        <Link
          to={`../${nextModule.connectedNode.node.slug}`}
          className='ModuleNavigation'
          style={{
            border: '1px solid #7EB99C',
            borderRadius: '25px',
            padding: '2rem',
            textAlign: 'center',
            minWidth: '20%',
            maxWidth: '40%'
          }}>
          Next module
        </Link>
      }
    </div>
   )
}

{/*
  Functions that calculate which module is before and after
*/}
function getPrevious(array, slug) {
  const index = array.findIndex(p => p.url === `/${slug}/`)
  return index !== 0 ? array[index-1] : null
}

function getNext(array, slug) {
  const index = array.findIndex(p => p.url === `/${slug}/`)
  return index !== array.length ? array[index+1] : null
}

ModuleNavigation.defaultProps = {
  id: '',
}

ModuleNavigation.propTypes = {
  id: PropTypes.string,
}

export default ModuleNavigation
