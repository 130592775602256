import React from "react"
import {graphql } from "gatsby"
import Img from "gatsby-image"

import Page from "../layouts/page"

import PDFViewer from "../components/page/pdfViewer"
import CTA from "../components/page/CTA"
import SEO from "../components/seo"
import ModuleNavigation from "../components/page/moduleNavigation"

export default function pageViewer({ data }) {

  const {title, content, pdf, slug, categories, image} = data.allWpPage.nodes[0]
  const imagePath = image?.image?.localFile?.childImageSharp?.fluid
  const caption = image?.image?.caption

  return (
    <Page>
      <SEO title={title} />

      {/* The title of the Page goes here */}
      <div className='subtitle'>{title}</div>

      {/* Checking to see if there is a splash image, and displaying it */}
      {imagePath &&
        <Img
          className='splash'
          fluid={imagePath}
          imgStyle={{ objectFit: 'cover' }}
          alt={caption} />
      }

      {/* The caption of the splash image goes here */}
      <div className='splash-caption' dangerouslySetInnerHTML={{ __html: caption}} />

      {/* The content of the Wordpress Page goes here */}
      <div className='page-content' dangerouslySetInnerHTML={{ __html: content }}/>

      {/* Checking if there is a PDF attached */}
      {pdf?.file !== null && <PDFViewer pdf={pdf} />}

      {/* Checking if this is a Module, and showing module navigation if so */}
      {categories?.nodes[0]?.name === 'Module' &&
        <ModuleNavigation slug={slug} />
      }

      {/* CTA component appears here */}
      <CTA />
    </Page>
  )
}

{/*
  A query that fetches the contents of every Page from Wordpress
*/}


export const query = graphql`
  query getPages($id: String!) {
    allWpPage(filter: {id: { eq: $id }}) {
      nodes {
        id
        title
        slug
        content
        date
        categories {
          nodes {
            name
          }
        }
        pdf {
          file {
            guid
          }
        }
        image {
          image {
            caption
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        featuredImage {
          node {
            caption
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
